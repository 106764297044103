<template>
    <div class="row">
        <div class="col-md-5">
            <div class="custom-control custom-checkbox">
                <input class="custom-control-input row" type="checkbox" id="fecha-espera-obligatoria" v-model="siniestro.urgente" v-bind:true-value="1" v-bind:false-value="0">
                <label for="fecha-espera-obligatoria" class="custom-control-label">{{ $t('general.urgente') }}</label><br>
            </div>
        </div>
        <div class="col-md-8">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.codigo') }}</label>
                <input v-model="siniestro.codigo" type="text" class="form-control input-sm" id="exampleInputEmail1" placeholder="Codigo">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.naviso') }}</label>
                <input v-model="siniestro.n_aviso" type="text" class="form-control input-sm" id="exampleInputEmail1" placeholder="Aviso">
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.compania') }}</label>
                <v-select v-model="selected_companyia" :options="listadocompanyias"></v-select>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.empresafactura') }}</label>
                <select v-model="selected_empresa" id="" class="form-control">
                    <option v-for="empresa in empresas" :key="empresa.id" :value="empresa">{{ empresa.nombre_empresa }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.companiadeseguros') }}</label>
                <v-select v-model="selected_companyia" :options="listadocompanyias"></v-select>
                <input v-model="siniestroaux.companyia_seguros_servicio" type="text" class="form-control input-sm">
            </div>
        </div>
        <div style="margin: 0;" v-if="pap" class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="idcompania">Id Compañía:</label>
                    <input v-model="idcompania" type="text" class="form-control input-sm">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="idcompania">Id Ramo:</label>
                    <input v-model="idramo" type="text" class="form-control input-sm">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="idcompania">Id Interviniente:</label>
                    <input v-model="idinterviniente" type="text" class="form-control input-sm">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.referencia') }}</label>
                <input v-model="siniestroaux.referencia_companyia_asitur_servicio" type="text" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('general.anyodelsiniestro') }}</label>
                <input v-model="siniestroaux.anno_servicio" type="text" class="form-control input-sm" id="exampleInputEmail1" placeholder="Codigo">
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.danyos') }}</label>
                <textarea v-model="siniestroaux.daños" class="form-control"></textarea>
            </div>  
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.observaciones') }}</label>
                <textarea v-model="siniestroaux.observaciones" class="form-control"></textarea>
            </div>  
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.notaimportante') }}</label>
                <textarea v-model="siniestroaux.nota_importante" class="form-control"></textarea>
            </div>  
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.viptexto') }}</label>      
            </div>  
        </div>
    </div>            
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default ({
    props:['servicio','id','guardartoken', 'servicioext'],
    setup() {
        return;
    },
    data() {
        return {
            selected_companyia: '',
            selected_empresa: '',
            siniestro: '',
            companyias:'',
            listadocompanyias: [],
            siniestroaux: [],
            listaempresas: [],
            empresas: [],
            pap:false,
            idcompania:'',
            idinterviniente:'',
            idramo:'',
        }
    },
    methods: {
        async listadocompanias() { 
            //get  modulos/pwgsapi/index.php/companias/simple
            const api = new PwgsApi;
            const datos = await api.get("companias/simple");
            this.companyias = datos.datos;
            for (var i = 0; i < this.companyias.length; i++) {
                this.listadocompanyias[i] = this.companyias[i].nombre;
            }
        },
        async listadoempresas() { 
            //GET  modulos/pwgsapi/index.php/empresas/simple
            const api = new PwgsApi;
            this.empresas = await api.get("empresas/todas");
            let idempresa = this.servicioext.datos.empresas_factura_id_empresa;
            console.log('idempresa',idempresa);
            this.selected_empresa = this.empresas.find(empresa => empresa.id == idempresa);
            console.log('selected_empresa', this.selected_empresa);
        },
        async cargarservicio() { 
            //get  modulos/pwgsapi/index.php/servicios/:id
            const api = new PwgsApi;
            const datos = await api.get("servicios/" + this.$props.id);
            this.siniestroaux = datos.datos;
        },
        async guardarsiniestro() { 
            //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
            // selected_companyia, siniestroaux.companyia_seguros_servicio ,
            try {
                const api = new PwgsApi;
                let subidadatos = {};
                if(this.pap){
                    subidadatos = {pap_interviniente:this.idinterviniente,pap_compania:this.idcompania,pap_ramo:this.idramo , urgente: this.siniestro.urgente, codigo: this.siniestro.codigo, n_aviso: this.siniestro.n_aviso, asitur_referencia: this.siniestroaux.referencia_companyia_asitur_servicio, pap_year: this.siniestroaux.anno_servicio, danos: this.siniestroaux.daños, observaciones: this.siniestroaux.observaciones, nota_importante: this.siniestroaux.nota_importante, id_empresa: this.selected_empresa.id }
                }else{
                    subidadatos = { urgente: this.siniestro.urgente, codigo: this.siniestro.codigo, n_aviso: this.siniestro.n_aviso, asitur_referencia: this.siniestroaux.referencia_companyia_asitur_servicio, pap_year: this.siniestroaux.anno_servicio, danos: this.siniestroaux.daños, observaciones: this.siniestroaux.observaciones, nota_importante: this.siniestroaux.nota_importante, id_empresa: this.selected_empresa.id }
                }
                await api.put('servicios/' + this.$props.id + '/modificar-servicio', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                this.$parent.$parent.$parent.$parent.cargardatos(this.$props.id);
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        comprobarPap(){
            if(this.servicio.compania.sistema == 'P.A.P.'){
                this.pap = true;
                this.idcompania = this.servicioext.datos.compannia_servicio;
                this.idramo = this.servicioext.datos.ramo_servicio;
                this.idinterviniente = this.servicioext.datos.pap_interviniente;
            }
        }
    },
    mounted() {
        this.comprobarPap();
        this.cargarservicio();
        this.listadocompanias();
        this.listadoempresas();
        this.siniestro = this.$props.servicio;
        this.selected_companyia = this.$props.servicio.compania.nombre;
    },
    watch: {
        id() {
            this.cargarservicio();
            this.listadocompanias();
            this.listadoempresas();
            this.siniestro = this.$props.servicio;
            this.selected_companyia = this.$props.servicio.compania.nombre;
        },
        guardartoken() {
            this.guardarsiniestro();
            //this.cargarservicio();
            this.$parent.$parent.$parent.$parent.cargardatos(this.$props.id);
        },
    }
})
</script>